import React from 'react';
import Resources from '../Resources/Resources';



const NotFound = () => {

    return (

        <Resources />
    );
};

export default NotFound;
