import React from 'react';
import './Intro.css';

const Intro = () => {
    return (
        <div className="contact-intro-containter">
            <div className='contact-intro-banner'>
                <div className="contact-intro-banner-description">
    
                <h1>Book A Session</h1>
                <h5>Begin your path to transformation today</h5>

            </div>
            </div>
        </div>
            );
};

export default Intro;
