import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoImage from '../../assets/logo/Replenish-logo.png';
import './NavigationMenu.scss';
import MenuToggle from './MenuToggle'; 
import MobileMenu from './MobileMenu';

const NavigationMenu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    const isHomePage = location.pathname === '/';


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);

            // Close the mobile menu if it's open when transitioning from mobile to desktop
            if (!isMobile && isMobileMenuOpen) {
                setIsMobileMenuOpen(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile, isMobileMenuOpen]);

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // ... your existing useEffect for handling window resizing

    return (
        <div className={`navigation-menu ${isMobile ? 'mobile' : 'desktop'}`}>
            {/* Desktop Buttons (conditionally rendered) */}

            
            {/* Left Buttons (desktop only) */}
            <div className="buttonsLeft">
                {!isMobile && (
                    <>
                        <Link to="/about">
                            <button>therapist</button>
                        </Link>
                        <p>|</p>
                        <Link to="/services">
                            <button>services</button>
                        </Link>
                        <p>|</p>
                        <Link to="/resources">
                            <button>resources</button>
                        </Link>
                    </>
                )}
            </div>

            {/* Logo Container */}
            
            <div className={`menu-logo-container ${isHomePage ? 'hide-logo' : 'fade-in-logo'}`}>
                <Link to="/">
                    <img src={LogoImage} alt="Logo" />
                </Link>
            </div>
            {/* Right Buttons (desktop only) */}
            <div className="buttonsRight">
                {!isMobile && (
                    <>
                        <Link to="/pricing">
                            <button>insurance & billing</button>
                        </Link>
                        <p>|</p>
                        <Link to="/faq">
                            <button>faqs</button>
                        </Link>
                        <p>|</p>


                        <Link to="/contact">
                            <button>contact us</button>
                        </Link>
                    </>
                )}
            </div>


            <MenuToggle isOpen={isMobileMenuOpen} onToggle={handleMobileMenuToggle} />
            <MobileMenu isOpen={isMobileMenuOpen} />
        </div>
  
    );
};

export default NavigationMenu;
