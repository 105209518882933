import React, { useState } from 'react';
import OwnerImage from '../../assets/about/DSC00429.webp';
import './OwnerInfo.scss';
import { Icon } from '@iconify/react';

const OwnerInfo = () => {
    const [showExperience, setShowExperience] = useState(false);
    const [showCertifications, setShowCertifications] = useState(false);
    const [showFunFacts, setShowFunFacts] = useState(false);
    const [showTrainings, setShowTrainings] = useState(false);
    const [showMemberships, setShowMemberships] = useState(false);


    const toggleCertifications = () => {
        setShowCertifications(!showCertifications);
    };
    const toggleTrainings = () => {
        setShowTrainings(!showTrainings);
    };
    const toggleMemberships = () => {
        setShowMemberships(!showMemberships);
    };
    const toggleExperience = () => {
        setShowExperience(!showExperience);
    };

    const toggleFunFacts = () => {
        setShowFunFacts(!showFunFacts);
    };

    return (
        <div className="owner-info-container">
            <div className='owner-info-container-wrapper'>

                <div className='owner-info-image-container'>
                    <img src={OwnerImage} alt="Francesca Cook" id='about-owner-image'></img>
                    <h2>Francesca Cook</h2>
                    <h5>Licensed Mental Health Counselor (MH 23205)</h5>
                 </div>
                <div className='owner-info-description'>
                    <h1>
                        <span className="large">Meet</span>
                        the{' '}
                        <span className="large">Counselors</span>
                    </h1>
                    <p>
                        Francesca Cook graduated in 2021 from Liberty University with a Master of Arts in Clinical Mental Health Counseling. She is a Licensed Mental Health Counselor and owner of Replenish Counseling, LLC. She draws from a diverse background, transitioning from the accounting world to becoming a counselor.
                    </p>
                    <p>
                        Francesca is dedicated to creating a safe space where individuals can freely express themselves. Utilizing a person-centered, cognitive behavioral, and relational approach in therapy sessions. She believes in the importance of each person&apos;s unique story and voice. For Francesca, being entrusted with the personal narratives of her clients is a privilege.
                    </p>
                    <p>Francesca works with children ages 4 and up. She offers a range of therapy and expressive techniques, including play therapy, Accelerated Resolution Therapy (ART), and sand tray therapy. Through these modalities, children can explore their emotions, build coping skills, and develop a strong sense of self. She believes that collaboration with families is crucial to a child's success in therapy. She works closely with parents and caregivers to understand the family dynamics, provide support, and offer strategies for fostering a positive and nurturing environment at home.</p>

                </div>

                <div className='owner-info-toggles'>
                    {/* Toggleable Experience Section */}
                    <div id='owner-info-experience' className="toggle-section" onClick={toggleExperience}>
                        <h2>
                            <span>{showExperience ? <Icon className='toggle-icon' icon='fa6-solid:graduation-cap' /> : <Icon className='toggle-icon' icon='fa6-solid:plus-minus' />}</span>
                            Experience:
                        </h2>
                        {showExperience && (
                            <div className='owner-info-popdown'>
                                <ul>
                                    <li>Children, Teens, & Adults</li>
                                    <li>Trauma</li>
                                    <li>Anxiety</li>
                                    <li>Women's Issues</li>
                                    <li>Relational Issues</li>
                                    <li>Parenting</li>
                                    <li>Sexual & Gender Issues</li>
                                    <li>Self-Harm/Suicidal Ideation</li>
                                    <li>Grief</li>
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* Toggleable Certifications Section */}
                    <div id='owner-info-certifications' className="toggle-section" onClick={toggleCertifications}>
                        <h2>
                            <span>{showCertifications ? <Icon className='toggle-icon' icon='fa6-solid:certificate' /> : <Icon className='toggle-icon' icon='fa6-solid:plus-minus' />}</span>
                            Certifications:
                        </h2>
                        {showCertifications && (
                            <div className='owner-info-popdown'>
                                <ul>
                                    <li>Certified Master Accelerated Resolution Therapist</li>
                                    <li>Certified Sand Tray Therapist</li>
                                    <li>Certified Clinical Trauma Professional</li>
                                    <li>Certified Save Your Marriage Before It Starts Facilitator</li>
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* Toggleable Trainings Section */}
                    <div id='owner-info-trainings' className="toggle-section" onClick={toggleTrainings}>
                        <h2>
                            <span>{showTrainings ? <Icon className='toggle-icon' icon='fa6-solid:book' /> : <Icon className='toggle-icon' icon='fa6-solid:plus-minus' />}</span>
                            Additional Training:
                        </h2>
                        {showTrainings && (
                            <div className='owner-info-popdown'>
                                <ul>                              
                                    <li>Emotional Focused Individual Therapy (Level 1)</li>
                                    <li>Trauma-Focused Cognitive Behavioral Therapy</li>
                                    <li>Forensic Mental Health Evaluation</li>
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* Toggleable Memberships Section */}
                    <div id='owner-info-memberships' className="toggle-section" onClick={toggleMemberships}>
                        <h2>
                            <span>{showMemberships ? <Icon className='toggle-icon' icon='fa6-solid:people-line' /> : <Icon className='toggle-icon' icon='fa6-solid:plus-minus' />}</span>
                            Memberships:
                        </h2>
                        {showMemberships && (
                            <div className='owner-info-popdown'>
                                <ul>
                                    <li>Florida Association for Play Therapy</li>
                                    <li>The American Association of Christian Counselors</li>
                                    <li>Florida Association of Infant Mental Health</li>
                                    <li>International Society of Accelerated Resolution Therapy</li>
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* Toggleable FunFacts Section */}
                    <div className="toggle-section" id='owner-info-funfacts' onClick={toggleFunFacts}>
                        <h2>
                            <span>{showFunFacts ? <Icon className='toggle-icon' icon='fa6-solid:face-smile' /> : <Icon className='toggle-icon' icon='fa6-solid:plus-minus' />}</span>
                            Fun Facts:
                        </h2>
                        {showFunFacts && (
                            <div className='owner-info-popdown'>
                                <li>Outside of being a counselor Francesca is a wife,
                                    dog mom, friend, reader, and loves the outdoors.</li>
                                <li>She was a dancer for 16 years and she played collegiate lacrosse.</li>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div >
    );
};

export default OwnerInfo;
