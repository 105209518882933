import React from 'react';
import './MissionStatement.scss'
import { motion } from 'framer-motion';

const MissionStatement = () => {


    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
        >
        <div className="mission-section">
            <div id='mission-statement' className="mission-info">
                <p>At Replenish Counseling, our mission is to provide compassionate and effective mental health services to individuals and families. We strive to create a safe space for personal growth, healing, and empowerment.</p>
            </div>
            </div>
        </motion.div>
    );
};

export default MissionStatement;
