import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, InlineIcon } from '@iconify/react';
import phoneIcon from '@iconify/icons-mdi/phone-outline';
import emailIcon from '@iconify/icons-mdi/email-multiple-outline';
import facebookIcon from '@iconify/icons-mdi/facebook';
import instagramIcon from '@iconify/icons-mdi/instagram';
import './Info.scss';

const contactDetails = [

    { value: '(407) 603-1677', id: 'phone-contact-detail', link: 'tel:4076031677', icon: phoneIcon },
    { value: 'Francesca@ReplenishCounselingLLC.com', id:'franki-email-address', link: 'mailto:Francesca@ReplenishCounselingLLC.com', icon: emailIcon },
    { value: 'Follow Us On Instagram', id: 'instagram-contact-detail', icon: instagramIcon, link: 'https://www.instagram.com/repelenish.counseling.llc?utm_source=contact' },
    { value: 'Like Us On Facebook', id: 'facebook-contact-detail', icon: facebookIcon, link: 'https://www.facebook.com/profile.php?id=61556330911397'}


];

const renderIcons = () => (
    [
    ].map(({ icon, link }, key) => (
        <Link to={link} key={key} target="_blank" rel="noopener noreferrer" className="icon-link">
            <Icon icon={icon} className="contact-social-icon" />
            { }
        </Link>
    ))
);

const Info = () => (
    <div className="contact-info-container">
    <div className='contact-info-container-wrapper'>
        
            <div className="contact-info-details-container">
                <h1>
                    
                    Contact{' '}
                    <span className="large">Information</span>
                </h1>
            {contactDetails.map(detail => (
                <p key={detail.value} className="info-detail">
                    <InlineIcon icon={detail.icon} /> 

                    <Link id={detail.id} to={detail.link} target="_blank" rel="noopener noreferrer">{detail.value}</Link>

                </p>
            ))}
        </div>
        <div className="info-icons-container">
                {renderIcons()}</div>

            <div className='contact-info-description-container'>
                <h1>

                    Choose{' '}
                    <span className="large">Mental Wellness</span>
                    
                </h1>
                <p>Embarking on a journey of self-discovery and positive change is a courageous step towards overcoming life's challenges.
                    Counseling provides a safe, confidential space to explore thoughts and feelings, fostering resilience and achieving
                    a more balanced and fulfilling life. If you're ready for this transformative experience, reach out through the contact
                    form below. Your mental and emotional well-being is important, and Replenish Counseling is here to support you.
                </p>
        </div>
        </div>
    </div>
);

export default Info;
