import React, { useEffect } from 'react';
import './About.scss';
import OwnerInfo from './OwnerInfo';
import MissionStatement from './MissionStatement';


    const About = () => {


    useEffect(() => {
        // Set the page title when the component mounts
        document.title = 'Replenish Counseling | About';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Replenish Counseling';
        };
    }, []); // Empty dependency array ensures the effect runs only once on mount

    return (
        <div>
        <div className="about-container">

        <MissionStatement />
                <OwnerInfo />

            </div>

        </div>
    );
};

export default About;
