import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';

const Privacy = () => {

    return (
        <div>
            <PrivacyPolicy />
        </div>
    );
}

export default Privacy;