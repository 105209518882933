// BreathingTechnique.js

import React from 'react';
import { Card } from 'react-bootstrap';

const BreathingTechnique = ({ title, description, image }) => {
    return (
        <Card className="breathe-card">

            <Card.Body>
            <div className="breathe-card-title">
                    <Card.Title><h2>{title}</h2></Card.Title>
                </div>
                <Card.Img variant="top" src={image} alt={title} className="breathe-card-image" />
                <div className="breathe-card-text">
                    <Card.Text>{description}</Card.Text>
                </div>
            </Card.Body>
        </Card>
    );
};

export default BreathingTechnique;
