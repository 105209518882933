import React from 'react';
import './Map.scss';

const Map = () => {
  return (
      <div className="map-container">
          

          <div className='map-container-wrapper'>
              <h1>
                  Map &{' '}
                  <span className="large">Directions</span>
              </h1>
      <iframe
        title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d870.5779161975064!2d-81.02125323045217!3d29.21438586897545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e6db7c086a8e41%3A0x89c19fbc44c895a0!2sReplenish%20Counseling!5e0!3m2!1sen!2sus!4v1707754505308!5m2!1sen!2sus"
                  allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
          </div>
      </div>
  );
};

export default Map;
