import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { Icon } from '@iconify/react';

const Header = () => {
    return (
        <div className='header-container' >
            <div className='header-wrapper'>

                <Link to="https://replenishcounselingllc.clientsecure.me/sign-in" target="_blank">
               <button id='header-client-portal-login-button'>
                    Client Portal Login
                    <Icon className='client-portal-login-button-icon' icon='fa6-solid:arrow-right-from-bracket'></Icon>              
                    </button> 
                </Link>

                <Link to="/contact"> <button id='header-contact-button'>
                    <Icon className='header-contact-button-icon' icon='fa6-solid:phone'></Icon>
                    Contact Us Today             
                </button>
                </Link>

            </div>

        </div>


    );
};

export default Header;
