// Layout.js Developed by CookCoding

import React, { useEffect, lazy, Suspense } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import './Layout.scss'; // Import your custom stylesheet
import Header from './Header';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import { useLocation } from 'react-router-dom';


const LazyBottomWave = lazy(() => import('./BottomWave'));
const LazyOrmondBeachCOCBadge = lazy(() => import('./OrmondBadge'));
const LazyPsychologyTodayBadge = lazy(() => import('./Verified'));
const LazyFooter = lazy(() => import('./Footer'));
const LazyContactFooter = lazy(() => import('./ContactFooter'));

const Layout = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Set the page title based on the route
        const routeTitles = {
            '/': 'Licensed Mental Health Counselor | Replenish Counseling | Daytona Beach, FL',
            '/faq': 'FAQs | Replenish Counseling',
            '/about': 'Therapist | Replenish Counseling',
            '/contact': 'Contact Us | Replenish Counseling',
            '/pricing': 'Payment & Insurance Options | Replenish Counseling',
            '/services': 'Types of Therapy | Replenish Counseling',
            '/resources': 'Additional Resources | Replenish Counseling',
            '/breathe': 'Breathing Techniques | Replenish Counseling',
            '/tictactoe': 'Tic-Tac-Toe | Replenish Counseling',
            '/recommendations': 'Product Recommendations | Replenish Counseling',
            '/privacy': 'Privacy Policy | Replenish Counseling',
            // Add more routes as needed
        };

        const pageTitle = routeTitles[location.pathname] || 'Replenish Counseling';
        document.title = pageTitle;
    }, [location.pathname]);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                {/* Link to Google Fonts asynchronously */}
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Lora:wght@400&family=Playfair+Display:wght@400&family=Quicksand:wght@400&display=swap"
                    media="print" onLoad="this.media='all'"
                />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&family=Dancing+Script&display=swap"
                    media="print" onLoad="this.media='all'"
                />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Josefin+Slab&display=swap"
                    media="print" onLoad="this.media='all'"
                />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Ultra&display=swap"
                    media="print" onLoad="this.media='all'"
                />

                {/* Preconnect to Google Fonts domain */}
                <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="true" />
            </Helmet>

            <div className="layout-container">
                <Header />
                    <NavigationMenu />

                <Container fluid tag="main">
                        {children }
                </Container>
                <Suspense fallback={<div>Loading...</div>}>
                <div className='badges-container'>
                        <LazyPsychologyTodayBadge />
                        <LazyOrmondBeachCOCBadge />
                    </div>
                    <LazyContactFooter />
                    <LazyFooter />
                    <LazyBottomWave />
                </Suspense>

            </div>
        </>
    );
};

export default Layout;
