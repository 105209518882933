// Services.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Services.scss';
import TeenImage from '../../assets/services/teenager.webp';
import SandImage from '../../assets/services/sand.webp';
import IndividualImage from '../../assets/services/individual.webp';
import ARTImage from '../../assets/services/art.webp';
import Premarital from '../../assets/services/premarital.webp';
import PlayImage from '../../assets/services/play.webp';


const Services = () => {

    return (
        <div className="services-container-wrapper">
<div className="services-container-header">         <h1>

                        <span className="large">Types</span> of{' '}
                        <span className="large">Therapy</span>
            </h1>

            </div>
            <div className='services-container-intro'>
                <p>We prioritize your unique journey,
                    offering a range of services designed to meet your individual needs.
                    Whether you're facing life transitions, relationship challenges, or
                    pursuing personal growth, our dedicated team is here to provide empathetic support.
                    You deserve a safe and non-judgmental environment to explore your thoughts, emotions,
                    and behaviors. Take the first step towards a more fulfilling life with our client-centered
                    approach, where your well-being is our priority. Your path to growth and resilience begins here.</p></div>
        <div className="services-container">
            <Link to="" className="service">
                <img src={IndividualImage} alt="Individual Therapy" className="service-image" loading="lazy" />
                <div className="service-overlay">
                    <h2>Individual Therapy</h2>
                    <p>In your session, you will be seen and heard by the counselor. Utilizing a collaborative approach; this is a safe place to work through your thoughts, emotions, and behaviors. We believe therapy is for anyone!</p>
                </div>
            </Link>

            <Link to="" className="service">
                <img src={TeenImage} alt="Teen Therapy" className="service-image" loading="lazy" />
                <div className="service-overlay">
                    <h2>Pre-Teen & Teen Therapy</h2>
                    <p>Your teen is growing and changing, they may be having attitude changes, trying to figure out who they are, or acting out. This is a place for your child to share about their life stressors, changes, emotions, and experiences. </p>
                    </div>
            </Link>

            <Link to="" className="service" id="premarital-therapy-card">
                <img src={Premarital} alt="Premarital Counseling" className="service-image" loading="lazy" />
                <div className="service-overlay">
                    <h2>Premarital Counseling</h2>
                    <p>An exciting new step! It&apos;s before the wedding and you want to make sure you are on the same page. In your sessions you will share your expectations for the future, discuss emotions, and work on communication. </p>
               </div>
            </Link>

            <Link to="" className="service" id="play-therapy-card">
                <img src={PlayImage} alt="Children (ages 4+) Therapy" className="service-image" loading="lazy" />
                <div className="service-overlay">
                    <h2>Child Therapy</h2>
                    <p>Unexplained emotional outbursts by your child? Children do not have the words to explain their feelings.  We use play therapy techniques for your child to be able to express their emotions and learn to grow and cope. </p>
                </div>
            </Link>

            <Link to="" className="service" id="sand-therapy-card">
                <img src={SandImage} alt="Sand Tray Therapy" className="service-image" loading="lazy" />
                <div className="service-overlay">
                    <h2>Sand Tray Therapy</h2>
                    <p>Are you feeling stuck? Cannot explain how you feel? Can&apos;t put words to the challenges and stressors you&apos;re facing? Sand tray is a therapeutic modality utilized to express your emotions where words fail. </p>
               </div>
            </Link>

            <Link to="" className="service" id="art-therapy-card">
                <img src={ARTImage} alt="Accelerated Resolution Therapy" className="service-image" loading="lazy" />
                <div className="service-overlay">
                    <h2>Accelerated Resolution Therapy (ART)</h2>
                    <p>Uses eye movements and other techniques to help people deal with trauma, depression, and other stress-related conditions</p>
                </div>
            </Link>
            </div>
            <div className='services-container-intro'>
                <p>At this time, we do NOT accept court-ordered cases.</p>
            </div>
        </div>   
    );
};

export default Services;
