import React from 'react';
import './Contact.scss';
import Intro from './Intro';
import Form from './Form';
import Info from './Info';

const Contact = () => {

    // Empty dependency array ensures the effect runs only once on mount
    return (
            <div className="contact-container">
                <Intro />
 
            <div className='contact-container-wrapper'>

             
            <div className="info-container">
                    <Info />

                </div>

            <div className='map-container'>
                    <Form />

                </div>
            </div>
            </div>
          
    );
};

export default Contact;
