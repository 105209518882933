import React, { useState, useEffect } from 'react';
import { init, send } from '@emailjs/browser';
import './Form.scss'; // Import your CSS file
import Map from '../Map/Map';
import Captcha from './Captcha'; // Import your Captcha component

const Form = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [phoneError, setPhoneError] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://cdn.jsdelivr.net/npm/@emailjs/browser@latest/dist/email.min.js`;
        script.onload = () => setLoading(false);
        document.body.appendChild(script);

        return () => document.body.removeChild(script);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setPhoneError('');
            setPhoneError('Please enter a valid email address.');
            return;
        }

        const phoneValidationResult = validatePhone(phone);
        if (phoneValidationResult !== true) {
            setPhoneError(phoneValidationResult);
            return;
        } else {
            setPhoneError('');
        }

        if (!captchaValue) {
            setPhoneError('Please complete the captcha.');
            return;
        }

        setSubmitted(true);

        if (loading) return;

        try {
            await init('csCxHo518bj_m6kE4'); // Replace with your actual Public Key
            await send(
                'service_oodrl4g',
                'template_yhoks4q', // Service ID & Template ID

                {
                    name: name,
                    from_email: email,
                    phone: phone,
                    message,
                }
            );
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
            setCaptchaValue(null); // Reset the reCAPTCHA value
        } catch (error) {
            console.error(error);
            setPhoneError('There was an error sending your message. Please try again later.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(formatPhoneNumber(value));
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const cleaned = phone.replace(/\D/g, ''); // Remove non-numeric characters
        const isValid = /^\d{10}$/.test(cleaned);

        return isValid ? true : 'Please enter a valid 10-digit phone number.';
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return cleaned;
    };

    return (
        <div className="contact-form-container">
            <div className='contact-form-container-wrapper'>
                <div className='contact-form-input-boxes'>
                    {phoneError && <p className="error-message">{phoneError}</p>}
                    {!submitted && (
                        <form onSubmit={handleSubmit}>
                            <h1>
                               
                                Contact{' '}
                                <span className="large">Form</span>
                            </h1>
                            {loading && <p>Loading...</p>}
                            {!loading && (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="name">Name:</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Jane Doe"
                                            value={name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="You@email.com"
                                            value={email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone:</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            placeholder="123-456-7890"
                                            value={phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group message-box">
                                        <label htmlFor="message">Message:</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            placeholder="How can we help?&#10;Medical Emergency? Call 911&#10;Suicidal Thoughts? Call 988"
                                            value={message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                    {phoneError && <p className="error-message">{phoneError}</p>}
                                    <div className='captcha-submit-buttons-container'>
                                        <div id='captcha'>
                                                <Captcha sitekey="6LeL2DEpAAAAAFiy4AMTZ7mJ7I2DX_KGbJyLpqcQ" onChange={(value) => setCaptchaValue(value)} />
                                        </div>
                                        <div id='contact-form-submit-button'>
                                            <button type="submit" disabled={loading}>
                                                {loading ? 'Sending...' : 'Send Message'}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </form>
                    )}
                    {submitted && (
                        <div className="thank-you-message">
                            <p>Thank you for contacting us. Someone will respond within 48 business hours.</p>
                        </div>
                    )}
                </div>
                <div className='contact-form-map-container'>
                    <Map />
                </div>
            </div>
        </div>
    );
};

export default Form;
