// Captcha.js

import React, { lazy, Suspense } from 'react';

const LazyReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const Captcha = ({ sitekey, onChange }) => {
    return (
        <div id='captcha'>
            <Suspense fallback={<div>Loading ReCAPTCHA...</div>}>
                <LazyReCAPTCHA sitekey={sitekey} onChange={onChange} />
            </Suspense>
        </div>
    );
};

export default Captcha;