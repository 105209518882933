import React from 'react';

const FourSevenEightBreathing = () => {
    return (
        <div>
            <p>
                Immerse yourself in tranquility with the rhythmic pattern of 4-7-8 Breathing. In the midst of life's demands, this technique offers a simple yet effective way to promote relaxation and mindfulness. By following the guided counts of inhalation, holding, and exhalation, you can foster a deep sense of calmness. Find a peaceful spot, sit comfortably, and let the gentle flow of 4-7-8 Breathing guide you to a serene state of mind.
            </p>
            <ol>
                <li>
                    <strong>Find a Quiet Space:</strong>
                    <ul>
                        <li>Sit in a comfortable position with your back straight.</li>
                        <li>Rest your hands on your knees or in a relaxed position.</li>
                    </ul>
                </li>
                <li>
                    <strong>Inhale Quietly Through Your Nose (Count of 4):</strong>
                    <ul>
                        <li>Inhale slowly and silently through your nose, counting to four.</li>
                    </ul>
                </li>
                <li>
                    <strong>Hold Your Breath (Count of 7):</strong>
                    <ul>
                        <li>Pause and hold your breath for a count of seven, maintaining a sense of tranquility.</li>
                    </ul>
                </li>
                <li>
                    <strong>Exhale Completely Through Your Mouth (Count of 8):</strong>
                    <ul>
                        <li>Exhale slowly and completely through your mouth, counting to eight as you release the breath.</li>
                    </ul>
                </li>
                <li>
                    <strong>Repeat the Cycle:</strong>
                    <ul>
                        <li>Continue the 4-7-8 Breathing cycle for several minutes, allowing the gentle rhythm to soothe your mind and body.</li>
                    </ul>
                </li>
            </ol>
            <p>
                4-7-8 Breathing is a portable tool for stress relief, and regular practice can enhance your overall well-being. Remember to adapt the counts to your comfort, and if you have any respiratory or health concerns, consult with a healthcare professional before incorporating this technique into your routine.
            </p>
        </div>
    );
};

export default FourSevenEightBreathing;
