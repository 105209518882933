// PrivacyPolicy.js

import React from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h2>Privacy Policy</h2>

            <p><strong>Information We Collect:</strong></p>
            <p>We collect personal information, such as names, phone numbers, and email addresses, when provided voluntarily through our contact form. Additionally, we may collect non-personal information through cookies and similar technologies.</p>

            <p><strong>How We Use Your Information:</strong></p>
            <p>The information we collect is used solely for the purpose of contacting clients and responding to inquiries. We do not sell or share this information with any third parties outside our company.</p>

            <p><strong>Google Analytics:</strong></p>
            <p>We use Google Analytics to analyze website traffic and user behavior. Google Analytics may collect anonymous data, and we use this information to improve our website and user experience.</p>

            <p><strong>User Rights:</strong></p>
            <p>You have the right to access, correct, or delete your personal information. If you have any concerns about the data we hold, please contact us.</p>

            <p><strong>Policy Changes:</strong></p>
            <p>We reserve the right to update this privacy policy as needed. Any changes will be posted on this page, and users will be notified accordingly.</p>
        </div>
    );
}

export default PrivacyPolicy;
