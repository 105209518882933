import React from 'react';
import './Pricing.scss'

const PricingDetails = () => {


    return (<div className='pricing-container-wrapper'>
<div className="pricing-intro-container">

                    <h1>

                        <span className="large">Ways</span> to{' '}
                        <span className="large">Pay</span>
                    </h1>
                </div>
                <div className="pricing-insurance-pay-container">
                <h2 className="pinline">
                    <span>Insurance Information</span>
                </h2>
                <p>
                        We happily accept various insurance plans to support you on your journey to emotional well-being.
                This ensures that you can focus on your mental health without added stress. Not all plans qualify,
                        check with provider for coverage details.
                        Our accepted insurances include:
                        <ul>
                    <li>Aetna</li>
                            <li>BlueCross & BlueShield</li>
                            <li>Cigna</li>
                            <li>Medicare</li>
                            <li>Optum</li>
                            <li>Oscar</li>
                    <li>Oxford</li>
                            <li>Tricare</li>
                            <li>United Healthcare</li>
                        </ul>                     
                
                    Your mental health matters,
                    and we're here to help make the process as smooth as possible.
                </p>
                <br />
                </div>
            <div className="pricing-self-pay-container">
                <h2 className="pinline">
                    <span>Self-Pay Rates</span>
                </h2>
                    
                    <p>
                        At Replenish Counseling, we also offer flexible options for those choosing to self-pay.
                        Our transparent self-pay rates are designed to provide you with quality mental health services without the constraints of insurance. Current Rates for each session type offered:

                       <ul>
                            <li>Initial Intake - $140</li>
                            <li>Individual 60 Minutes - $125</li>
                            <li>Individual 45 Minutes - $100</li>
                    <li>Premarital 60 Minutes - $125</li>
                            <li>Family 60 Minutes - $150</li>
                            </ul>
                        
                   
                        We accept the following payment methods: MasterCard, Visa, Discover, American Express, Debit Cards, Checks, and Cash.
                    </p>

        </div>
        </div>

    );
};

export default PricingDetails;
