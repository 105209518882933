import React from 'react';

const AlternateNostrilBreathing = () => {
    return (
        <div>
            <p>
                Step into a realm of tranquility with Alternate Nostril Breathing. Amidst the rush of everyday life, this practice invites you to center your energy and find balance. By alternating the flow of breath between nostrils, you harmonize the left and right sides of your brain, fostering a sense of calm and clarity. Sit comfortably, close your eyes, and embark on this journey of balanced breathing to bring peace to your mind and body.
            </p>
            <ol>
                <li>
                    <strong>Find a Comfortable Seated Position:</strong>
                    <ul>
                        <li>Sit with your spine straight and shoulders relaxed.</li>
                        <li>Place your left hand on your knee, palm facing upward.</li>
                        <li>Position your right hand in front of your face, with the index and middle fingers between your eyebrows.</li>
                    </ul>
                </li>
                <li>
                    <strong>Block the Right Nostril:</strong>
                    <ul>
                        <li>Use your right thumb to gently close your right nostril.</li>
                    </ul>
                </li>
                <li>
                    <strong>Inhale Through the Left Nostril:</strong>
                    <ul>
                        <li>Inhale deeply and slowly through your left nostril.</li>
                    </ul>
                </li>
                <li>
                    <strong>Switch Sides:</strong>
                    <ul>
                        <li>Release the right nostril and use your right ring finger to close your left nostril.</li>
                    </ul>
                </li>
                <li>
                    <strong>Exhale Through the Right Nostril:</strong>
                    <ul>
                        <li>Exhale slowly and completely through your right nostril.</li>
                    </ul>
                </li>
                <li>
                    <strong>Repeat the Cycle:</strong>
                    <ul>
                        <li>Continue alternating nostrils for several cycles, focusing on the flow of breath and maintaining a calm awareness.</li>
                    </ul>
                </li>
            </ol>
            <p>
                Alternate Nostril Breathing is a powerful tool to enhance mental clarity and reduce stress. As you practice, let go of tension and embrace the peaceful rhythm of your breath. If you have any respiratory concerns or health conditions, consult with a healthcare professional before incorporating this technique into your routine.
            </p>
        </div>
    );
};

export default AlternateNostrilBreathing;
