import React from 'react';

const PursedLipBreathing = () => {
    return (
        <div>
            <p>
                Step into a moment of calm with Pursed Lip Breathing. In the midst of life's demands, this technique offers a simple and effective way to enhance your breathing and promote relaxation. By focusing on a controlled exhale through pursed lips, you can ease tension and bring a sense of serenity to your mind and body. Find a comfortable space, sit with ease, and let the gentle rhythm of Pursed Lip Breathing guide you to a state of peacefulness.
            </p>
            <ol>
                <li>
                    <strong>Find a Comfortable Seated Position:</strong>
                    <ul>
                        <li>Sit in a relaxed position with your back straight and shoulders relaxed.</li>
                    </ul>
                </li>
                <li>
                    <strong>Inhale Slowly Through Your Nose:</strong>
                    <ul>
                        <li>Inhale gently and slowly through your nose, allowing your lungs to fill with air.</li>
                    </ul>
                </li>
                <li>
                    <strong>Purse Your Lips:</strong>
                    <ul>
                        <li>Pucker your lips as if you were about to whistle.</li>
                    </ul>
                </li>
                <li>
                    <strong>Exhale Slowly Through Pursed Lips:</strong>
                    <ul>
                        <li>Exhale slowly and completely through your pursed lips, as if you were blowing out a candle.</li>
                    </ul>
                </li>
                <li>
                    <strong>Repeat the Cycle:</strong>
                    <ul>
                        <li>Continue this rhythmic cycle of inhaling through your nose and exhaling through pursed lips for several minutes, maintaining a sense of ease.</li>
                    </ul>
                </li>
            </ol>
            <p>
                Pursed Lip Breathing is a portable technique you can practice anywhere, providing a quick reset for your breath and mind. If you have any respiratory or health concerns, consult with a healthcare professional before incorporating this technique into your routine.
            </p>
        </div>
    );
};

export default PursedLipBreathing;
