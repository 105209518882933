// Breathe.js

import React, { useState } from 'react';
import './Breathe.scss'; // Import your custom stylesheet
import { Container, Form } from 'react-bootstrap'; // Import Bootstrap components
import Image1 from '../../assets/breathe/breath1.webp';
import Image2 from '../../assets/breathe/breath2.webp';
import Image3 from '../../assets/breathe/breath3.webp';
import Image4 from '../../assets/breathe/breath4.webp';
import Image5 from '../../assets/breathe/breath5.webp';
import BreathingTechnique from './BreathingTechnique'; // Import the BreathingTechnique component
import BoxBreathing from './Techniques/BoxBreathing';
import DiaphragmaticBreathing from './Techniques/DiaphragmaticBreathing';
import AlternateNostrilBreathing from './Techniques/AlternateNostrilBreathing';
import FourSevenEightBreathing from './Techniques/FourSevenEightBreathing';
import PursedLipBreathing from './Techniques/PursedLipBreathing';



const breathingTechniquesData = [
    {
        title: 'Diaphragmatic Breathing',
        description: <DiaphragmaticBreathing />,
        image: Image1,
    },
    {
        title: 'Box Breathing',
        description: <BoxBreathing />,
        image: Image2,
    },
    {
        title: 'Alternate Nostril Breathing',
        description: <AlternateNostrilBreathing />,
        image: Image3,
    },
    {
        title: '4-7-8 Breathing',
        description: <FourSevenEightBreathing />,
        image: Image4,
    },
    {
        title: 'Pursed-Lip Breathing',
        description: <PursedLipBreathing />,
        image: Image5,
    },
];
// Breathe.js


const Breathe = () => {
    const [selectedTechnique, setSelectedTechnique] = useState(null);

    const handleTechniqueSelect = (event) => {
        const selectedValue = event.target.value;
        const selectedTechnique = breathingTechniquesData.find((technique) => technique.title === selectedValue);
        setSelectedTechnique(selectedTechnique);
    };

    return (
        <Container className="breathe-container">
            <h1>Breathing Techniques</h1>
            <p>Explore various breathing techniques for relaxation and anxiety:</p>

            <Form.Select onChange={handleTechniqueSelect} value={selectedTechnique ? selectedTechnique.title : ''}>
                <option value="" disabled>Select a breathing technique...</option>
                {breathingTechniquesData.map((technique, index) => (
                    <option key={index} value={technique.title}>
                        {technique.title}
                    </option>
                ))}
            </Form.Select>
            <br></br>
            {selectedTechnique && (
                <BreathingTechnique
                    title={selectedTechnique.title}
                    description={selectedTechnique.description}
                    image={selectedTechnique.image}
                />
            )}

            <p>These breathing techniques can be practiced regularly to improve relaxation, reduce anxiety, and enhance overall well-being. It's important to find a technique that feels comfortable and effective for you.</p>

    
        </Container>
    );
};

export default Breathe;
