import React from 'react';
import { useSpring, animated } from 'react-spring';
import AboutImage from '../../assets/home/front_door.webp';
import CompanyLogo from '../../assets/logo/Replenish-logo.png';
import './HomeIntro.scss'; // Import your custom stylesheet
import ChaosAnimation from './ChaosAnimation';
import { Helmet } from 'react-helmet';

const HomeIntro = () => {
    // Animation config
    const logoAnimation = useSpring({
        opacity: 1,
        transform: 'translateY(0)',
        from: { opacity: 0, transform: 'translateY(100%)' },
        config: { duration: 750 }, // Adjust duration as needed
    });

    return (
        <div>
            <section className="intro-section">

                <Helmet><link rel="preload" as="image" href="../../assets/home/clouds-8459053_1920.webp" /></Helmet>
                <animated.img
                    fetchpriority="high"
                    src={CompanyLogo}
                    alt="Replenish Counseling Logo"
                    id="home-page-logo-id"
                    className="home-page-logo"
                    style={logoAnimation}
                />
                <h1>Empowering Your Journey to Emotional Well-Being</h1>
            </section>


            <section rel="preload"  id='chaos-container' className="home-content-section">
                <ChaosAnimation />

            </section>
                <div className="home-content-wrapper">

                    <img
                        fetchpriority="high"
                        src={AboutImage}
                        alt="About Us"
                        id="home-content-image-id"
                        className="home-content-image"
                    />

                <div className="content-info">
                    <h1>
                        <span className="large">Welcome</span>
                        {' '}to{' '}
                        <span className="large">Replenish Counseling!</span>
                    </h1>
                        <h3>Beach Street office in historic downtown Daytona now open!</h3>
    
                            <p>Counseling is more than just a conversation; our office
                            provides diverse therapeutic approaches beyond talk therapy. Whether
                            for children aged 4+, pre-teens, teens, or adults, we offer
                        services tailored to meet your unique needs.</p>


                        <p>
                            Our Founder, Francesca Cook, LMHC, is dedicated to providing a
                            safe and empathetic space for individuals to be themselves and
                            express their needs. Her encouraging environment supports clients
                            in navigating challenging emotions and overcoming obstacles.
                    </p>

                    <p>Curious about making positive changes? Let's chat
                        and explore the possibilities together.
                    </p>
                    </div>
                </div>
        </div>
    );
};

export default HomeIntro;
