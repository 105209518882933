import React, { Component } from 'react';
import './TicTacToe.css'; // Import your custom stylesheet

class TicTacToe extends Component {
    static displayName = TicTacToe.name;


    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            squares: Array(9).fill(null),
            currentPlayer: 'X',
            scores: {
                X: 0,
                O: 0,
            },
        };
        this.handleSquareClick = this.handleSquareClick.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleSquareClick(index) {
        if (this.state.squares[index] === null) {
            const newSquares = this.state.squares.slice();
            newSquares[index] = this.state.currentPlayer;
            this.setState({ squares: newSquares });

            if (this.checkWinner(newSquares)) {
                const currentPlayer = this.state.currentPlayer;
                const updatedScores = { ...this.state.scores };
                updatedScores[currentPlayer]++;
                this.setState({ scores: updatedScores });
            } else {
                const nextPlayer = (this.state.currentPlayer === 'X') ? 'O' : 'X';
                this.setState({ currentPlayer: nextPlayer });
            }
        }
    }

    checkWinner(squares) {
        const winningConditions = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];

        for (let i = 0; i < winningConditions.length; i++) {
            const [a, b, c] = winningConditions[i];
            if (squares[a] === squares[b] && squares[b] === squares[c] && squares[a] !== null) {
                return true;
            }
        }

        return false;
    }

    handleReset() {
        this.setState({
            squares: Array(9).fill(null),
            currentPlayer: 'X',
        });
    }

    renderSquare(index) {
        return (
            <button className="square" onClick={() => this.handleSquareClick(index)}>
                {this.state.squares[index]}
            </button>
        );
    }

    render() {
        const currentPlayer = this.state.currentPlayer;
        let message = `Player ${currentPlayer}'s Turn!`;

        if (this.checkWinner(this.state.squares)) {
            const currentPlayer = this.state.currentPlayer;
            message = `Player ${currentPlayer} WINS!!`;
        }

        return (
            <div style={{ textAlign: 'center' }} >
                <h1>Tic-Tac-Toe</h1>
                

                <div className="board">
                    <div className="scoreboard">
                        <h2>{message}</h2>
                        <div style={{ textAlign: 'center' }}>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        {this.renderSquare(0)}
                        {this.renderSquare(1)}
                        {this.renderSquare(2)}
                    </div>
                    <div className="row">
                        {this.renderSquare(3)}
                        {this.renderSquare(4)}
                        {this.renderSquare(5)}
                    </div>
                    <div className="row">
                        {this.renderSquare(6)}
                        {this.renderSquare(7)}
                        {this.renderSquare(8)}
                    </div>
                    <br></br><br></br>
                    <div className="scoreboard">
                        <h2>{message}</h2><br></br>

                    </div>
                </div>
                <div className="reset-button">
                    <button className="btn btn-secondary" onClick={this.handleReset}>Reset Game</button>
                </div>
            </div>
        );
    }
}

export default TicTacToe;
