import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout/Layout'; // Correct the import path
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component


function App() {

    const location = useLocation();
        return (
            <Layout>
                <ScrollToTop /> {/* Add the ScrollToTop component */}
                    <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return (
                            <Route
                                key={index}
                                {...rest}
                                element={React.cloneElement(element, { location })}
                            />
                        );
                    })}
                        </Routes>
            </Layout>
        );
    }

export default App;