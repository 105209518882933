import React from 'react';
import { Link } from 'react-router-dom';
import './Resources.scss'; // Import your custom stylesheet
import MoreImage from '../../assets/home/tic-tac-toe.webp';



const Resources = () => {


    return (
       
            <section className="more-content-section">
                
            <div className="more-content-wrapper">
                <img className='more-content-image' src={MoreImage} alt="Additional Resources"></img>
           
                <div className="more-content-header">
                    <h1>
                        additional{' '}
                        <span className="large">Resources</span>
                    </h1>
                </div>
                        <div className="more-content-info">
                <p>Learn breathing techniques for controlling anxiety, explore product recommendations, or play a game of Tic-Tac-Toe.</p>
                </div>
                <div className="more-content-btn">
                    <Link to="/breathe">Breathing Techniques</Link>
                    <Link to="/recommendations">Product Recommendations</Link>
              
                    <Link to="/tictactoe">Play Tic-Tac-Toe</Link>
                </div>
            </div>
            </section>

    );
};

export default Resources;
