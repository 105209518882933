// ChaosAnimation.js

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './ChaosAnimation.scss';
import { Helmet } from 'react-helmet';

const phrases = [
    'Anxiety?',
    'Depression?',
    'Relationship Issues?',
    'Stress?',
    'Grief?',
    'Self-Harm?',
    'Trauma?',
    'Low Self-esteem?',
    'Life Transitions?',
    'Family Conflict?',
    'Anger Management?',
    'Acting Out?',
    'Tantrums?',
    'Career Challenges?',
    'Phobias?',
    'Parenting Struggles?',
    'Personal Growth?',
];
const getRandomPosition = (width, height, existingPositions) => {
    const maxAttempts = 15;
    let attempt = 0;

    while (attempt < maxAttempts) {
        const randomPosition = {
            // Calculate a random position within specified margins
            x: Math.random() * (width - 100), // Adjusted for margins
            y: Math.random() * (height - 25), // Adjusted for margins
        };

        // Check for overlapping positions
        const overlapping = existingPositions.some(
            (pos) =>
                randomPosition.x < pos.x + 100 &&
                randomPosition.x + 100 > pos.x &&
                randomPosition.y < pos.y + 40 &&
                randomPosition.y + 40 > pos.y
        );

        if (!overlapping) {
            return randomPosition;
        }

        attempt++;
    }

    // If maxAttempts reached, return a fallback position
    return { x: 0, y: -2000, opacity: 0 };
};
const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};

const ChaosAnimation = () => {
    const [phrasesList, setPhrasesList] = useState([]);
    const [shuffledPhrases, setShuffledPhrases] = useState([]);
    const [lifeMessageShown, setLifeMessageShown] = useState(false);

    useEffect(() => {
        setShuffledPhrases(shuffleArray(phrases));
    }, []);

    useEffect(() => {
        const container = document.getElementById('chaos-container');
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;

        const addNewPhrase = () => {
            if (shuffledPhrases.length > 0 && phrasesList.length < 20) {
                const [nextPhrase, ...remainingPhrases] = shuffledPhrases;
                setShuffledPhrases(remainingPhrases);

                if (!phrasesList.find((item) => item.phrase === nextPhrase)) {
                    const randomPosition = getRandomPosition(
                        0.9 * containerWidth,
                        0.9 * containerHeight,
                        phrasesList.map((item) => item.position)
                    );

                    setLifeMessageShown(false); // Reset life message visibility flag
                    setPhrasesList((prevList) => [
                        ...prevList,
                        {
                            phrase: nextPhrase,
                            position: randomPosition,
                        },
                    ]);
                }
            } else if (!lifeMessageShown) { // Show life message when no more phrases
                setLifeMessageShown(true);
            }
        };

        const intervalId = setInterval(addNewPhrase, 400);

        return () => clearInterval(intervalId);
    }, [phrasesList, shuffledPhrases, lifeMessageShown]);

    return (
        <div className="chaos-animation" id="chaos-container">
            {phrasesList.map(({ phrase, position }, index) => (
                <motion.div
                    key={index}
                    className="animated-phrase"
                    style={{ top: position.y, left: position.x }}
                    initial={{
                        opacity: 0,
                        scale: 5,
                        x: Math.random() * 500 - 250,
                        y: Math.random() * 500 - 250,
                    }}
                    animate={{
                        scale: 1,
                        opacity: !lifeMessageShown ? 1 : 0.5, // Fade existing phrases on message display
                        y: 0,
                        x: 0,
                    }}
                    transition={{ duration: 0.5 }}
                >
                    {phrase}
                </motion.div>
            ))}
            {lifeMessageShown && ( // Conditionally render life message
                <motion.div

                    className="life-phrase"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    Life's<br></br>Complicated..
                </motion.div>
            )}
        </div>
    );
};

export default ChaosAnimation;