import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const MenuToggle = ({ isOpen, onToggle }) => {
    const handleClick = (event) => {
        onToggle();
        event.preventDefault(); // Prevent default link behavior if needed
    };

    return (
        <div className={`mobile-menu-toggle ${isOpen ? 'active' : ''}`} onClick={handleClick} onKeyDown={handleClick} aria-label={isOpen ? 'Close Menu' : 'Open Menu'}>

            <span className='mobile-menu-text'>{isOpen ? 'Close Menu' : 'View Menu'}</span>
            <FontAwesomeIcon
                icon={isOpen ? faTimes : faBars}
                className={`icon-menu close-icon ${isOpen ? 'spin' : ''}`}
            />
        </div>
    );
};

export default MenuToggle;
