// AppRoutes.js

import About from "./components/About/About";
import Breathe from "./components/Breathe/Breathe";
import Contact from "./components/Contact/Contact";
import FAQ from "./components/FAQ/FAQ";
import Home from "./components/Home/Home";
import Pricing from "./components/Pricing/Pricing";
import Map from "./components/Map/Map";
import Resources from "./components/Resources/Resources";
import Services from "./components/Services/Services";
import TicTacToe from "./components/TicTacToe/TicTacToe";
import NotFound from "./components/Layout/NotFound";
import Recommendations from "./components/Recommendations/Recommendations";
import Privacy from "./components/Privacy/Privacy";

const AppRoutes = [
    { key: 'home', index: true, element: <Home /> },
    { key: 'about', path: '/about', element: <About /> },
    { key: 'breathe', path: '/breathe', element: <Breathe /> },
    { key: 'recommendations', path: '/recommendations', element: <Recommendations /> },
    { key: 'contact', path: '/contact', element: <Contact /> },
    { key: 'faq', path: '/faq', element: <FAQ /> },
    { key: 'pricing', path: '/pricing', element: <Pricing /> },
    { key: 'privacy', path: '/privacy', element: <Privacy /> },
    { key: 'map', path: '/map', element: <Map /> },
    { key: 'resources', path: '/resources', element: <Resources /> },
    { key: 'services', path: '/services', element: <Services /> },
    { key: 'tictactoe', path: '/tictactoe', element: <TicTacToe /> },
    { key: 'not-found', path: '*', element: <NotFound /> }
];

export default AppRoutes;
