import React from 'react';
import './Pricing.scss'; // Import your custom stylesheet
import PricingClosingImage from '../../assets/pricing/woman-falled-tree.webp'
import PricingHeaderImage from '../../assets/pricing/calculator-pen.webp';
import PricingDetails from './PricingDetails';

const Pricing = () => {

    return (
        <div className="pricing-container">
                <div className='pricing-image-container'>

                    <img src={PricingHeaderImage} alt="Calculator & Pen" id='pricing-header-image' />

            </div>
            
            <PricingDetails />

            <div className='pricing-closing-image-container'>

                <img src={PricingClosingImage} alt="Free" id='pricing-closing-image' />
            </div>

        </div>
        );
    }

export default Pricing;
