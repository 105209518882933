// ProductList.js

import React from 'react';
import './ProductList.scss';

const ProductList = ({ products }) => {
    return (
        <div className='recommendation-product-container'>
            {products.map((product, index) => (
                <div className='recommendation-item-container' key={index}>
                    <h3 className='recommendation-product-name'>{product.name}</h3>
                    <img className='product-image' src={product.imageUrl} alt={product.name} />
                    <p className='product-description'>{product.description}</p>
                    <a className='product-link' href={product.url} target="_blank" rel="noopener noreferrer">
                        View on Amazon
                    </a>
                </div>
            ))}
        </div>
    );
};

export default ProductList;
