import React from 'react';

const BoxBreathing = () => {
    return (
        <div>
            <p>
                Embrace a moment of serenity with Box Breathing. In the midst of life's demands, this technique offers a calming ritual designed to bring balance to your mind and body. By following a simple pattern of inhalation, holding, exhalation, and holding, you can reset your focus and invite tranquility. Find a quiet space, take a seat, and let the rhythmic cycle of Box Breathing guide you to a centered state of calmness.
            </p>
            <ol>
                <li>
                    <strong>Find a Comfortable Position:</strong>
                    <ul>
                        <li>Sit in a relaxed and upright position, ensuring your spine is straight.</li>
                        <li>Place your hands on your knees or in a comfortable position.</li>
                    </ul>
                </li>
                <li>
                    <strong>Inhale Slowly Through Your Nose (Count of 4):</strong>
                    <ul>
                        <li>Inhale deeply and slowly through your nose, counting to four as you fill your lungs with air.</li>
                    </ul>
                </li>
                <li>
                    <strong>Hold Your Breath (Count of 4):</strong>
                    <ul>
                        <li>Pause and hold your breath for a count of four. Maintain a sense of calm during this pause.</li>
                    </ul>
                </li>
                <li>
                    <strong>Exhale Slowly Through Your Mouth (Count of 4):</strong>
                    <ul>
                        <li>Exhale slowly and completely through your mouth, counting to four as you release the air.</li>
                    </ul>
                </li>
                <li>
                    <strong>Hold Your Breath Again (Count of 4):</strong>
                    <ul>
                        <li>Once again, hold your breath for a count of four, preparing for the next inhalation.</li>
                    </ul>
                </li>
                <li>
                    <strong>Repeat:</strong>
                    <ul>
                        <li>Continue the Box Breathing cycle for a few minutes, maintaining a steady and focused rhythm.</li>
                    </ul>
                </li>
            </ol>
            <p>
                Box Breathing is a versatile technique that can be practiced anywhere, offering a moment of calm amidst the chaos. Remember to adapt the breath counts to your comfort, and if you have any specific health concerns, consult with a healthcare professional.
            </p>
        </div>
    );
};

export default BoxBreathing;
