import React from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = ({ isOpen }) => {

    const closeMenu = () => isOpen(false);

    return (
        <div className={`mobile-menu ${isOpen ? 'visible' : 'not-visible'}`}>
            <Link to="/about" onClick={closeMenu}><button>therapist</button></Link>
            <Link to="/services" onClick={closeMenu}><button>services</button></Link>
            <Link to="/pricing" onClick={closeMenu}><button>insurance & billing</button></Link>
            <Link to="/contact" onClick={closeMenu}><button>contact us</button></Link>
            <Link to="/faq" onClick={closeMenu}><button>faqs</button></Link>
            <Link to="/resources" onClick={closeMenu}><button>resources</button></Link>
        </div>
    );
};

export default MobileMenu;
