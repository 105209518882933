import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './FAQ.scss';

const FAQ = () => {

    const faqData = [
        {
            question: 'What is counseling?',
            answer:( '',
                            < span >
                    Counseling is a professional service that helps individuals cope with various challenges, improve mental health, and enhance overall well-being.
                </span >
            )
        },
        {
            question: 'Do you accept Insurance?',
            answer: (
                <span>
                    Yes! We accept plans from Aethna, BlueCross & BlueShield, Cigna, Medicare, Oscar, Tricare, & United Healthcare. Visit our {' '}
                    <Link to="/pricing">Insurance & Billing</Link> page for more information.
                </span>
            ),
        },
        {
            question: 'How much does counseling cost?',
            answer: (
                <span>
                    The cost of counseling varies. You can find detailed information on our{' '}
                    <Link to="/pricing">Pricing</Link> page.
                </span>
            ),
        },
        {
            question: 'Do you offer virtual sessions?',
            answer: (
                <span>
       Yes! We offer a HIPPA compliant telehealth option.
                </span>
            ),
        },
        {
            question: 'Do you offer Christian counseling?',
            answer: (
                <span>
                    Yes! Christian counseling is available upon request. 
                </span>
            ),
        },
    ];
    
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <div className="faq-intro-container">

                <h1>
                    <span className="large">Frequently</span> asked{' '}
                    <span className="large">Questions</span>
                </h1>
            </div>
        <div className='faq-container-wrapper'>
            

            <div className="faq-item-container">
            {faqData.map((item, index) => (
                <div key={index} className="faq-item">
                    <div className={`faq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => handleToggle(index)}>
                        {item.question}
                    </div>
                    {activeIndex === index && (
                        <div className="faq-answer">
                            {item.answer}
                        </div>
                    )}
                </div>
            ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
