
import React, { useState, useEffect } from 'react';
import ProductList from './ProductList';
import './Recommendations.scss'; // You can create a separate CSS file for styling

const Recommendations = () => {

    const productData = [

        {
            name: "Beautiful Oops!",
            url: "https://amzn.to/3Un4IuJS",
            imageUrl: "https://m.media-amazon.com/images/I/91v1p0mTw7L._SY425_.jpg",
            description: 'This interactive book demonstrates how every mistake is an opportunity to make something beautiful.',
            category: ["Books"],
            subcategory: ["Kids"]
        },
        {
            name: "The Whole-Brain Child",
            url: "https://amzn.to/4bq0J6z",
            imageUrl: "https://m.media-amazon.com/images/I/71FjTgAVgyL._SY425_.jpg",
            description: '12 Revolutionary Strategies to Nurture Your Child\'s Developing Mind',
            category: ["Books"],
            subcategory: [""]
        },
        {
            name: "What Happened to You",
            url: "https://amzn.to/496tgwg",
            imageUrl: "https://m.media-amazon.com/images/I/61ntLOXDj6L._SY425_.jpg",
            description: 'Conversations on Trauma, Resilience, and Healing',
            category: ["Books"],
            subcategory: ["Trauma"]
        },
        {
            name: "Dibs in Search of Self",
            url: "https://amzn.to/496tmUE",
            imageUrl: "https://m.media-amazon.com/images/I/61TVsIvw7gL._SY342_.jpg",
            description: 'The Renowned, Deeply Moving Story of an Emotionally Lost Child Who Found His Way Back',
            category: ["Books"],
            subcategory: [""]
        },
        {
            name: "SHASHIBO Shape Shifting Box",
            url: "https://amzn.to/3HNNW0j",
            imageUrl: "https://m.media-amazon.com/images/I/61Lm9gkTNUL._AC_SY355_.jpg",
            description: 'Fidget Cube w/ 36 Rare Earth Magnets - Transforms Into Over 70 Shapes',
            category: ["Toys"],
            subcategory: [""]
        },
        {
            name: "Schylling NeeDoh Nice Cube",
            url: "https://amzn.to/3HR0UKD",
            imageUrl: "https://m.media-amazon.com/images/I/61ogaa5XfBL._AC_SY355_.jpg",
            description: 'Sensory Fidget Toy for Your Best Mellow and Chill',
            category: ["Toys"],
            subcategory: [""]
        },
        {
            name: "Sensory Slug Toy Fidget Pack",
            url: "https://amzn.to/49qUgGQ",
            imageUrl: "https://m.media-amazon.com/images/I/61vCEwZ3t5L._AC_SX425_.jpg",
            description: 'Everyone needs such slug toy to relieve the pressure of life, work, and study.',
            category: ["Toys"],
            subcategory: [""]
        },
        {
            name: 'Dinosaurs Divorce',
            author: 'Marc Brown & Laurie Krasny Brown',
        url: "https://amzn.to/3PAQZwS",
        imageUrl: "https://m.media-amazon.com/images/I/61zc6ccNLAS._SX342_SY445_.jpg",
        description: "Dinosaurs Divorce helps readers understand what divorce means",
            category: ["Books"],
            subcategory: [""]
        },
        {
            name: 'Lifetimes: The Beautiful Way to Explain Death to Children',
            author: 'Bryan Mellonie & Robert Ingpen',
            url: "https://amzn.to/4cx0QO7",
            imageUrl: "https://m.media-amazon.com/images/I/51bLVbcXTIL._SX342_SY445_.jpg",
            description: "Explain life & death in a sensitive, caring, beautiful way",
            category: ["Books"],
            subcategory: ["Kids", "Grief"]
        },
        {
            name: 'The Invisible String',
            author: 'Patrice Karst',
            url: 'https://amzn.to/3x4TpNT',
            imageUrl: 'https://m.media-amazon.com/images/I/91XwsrpWFcL._SY466_.jpg',
            description: "The perfect tool for coping with all kinds of separation",
            category: ["Books"],
            subcategory: ["Kids", "Grief", "Divorce", "Life Transistions"]
        },
        {
            name: 'The Invisible Leash',
            author: 'Patrice Karst',
            url: 'https://amzn.to/4apYkHF',
            imageUrl: 'https://m.media-amazon.com/images/I/91Tr6XGol-L._SY466_.jpg',
            description: "When our pets aren't with us anymore, an Invisible Leash connects our hearts to each other. Forever.",
            category: ["Books"],
            subcategory: ["Kids", "Grief"]
        },
        {
            name: 'Tear Soup: A Recipe for Healing After Loss',
            author: 'Pat Schwiebert & Chuck DeKlyen',
            url: 'https://amzn.to/4aqxwXT',
            imageUrl: 'https://m.media-amazon.com/images/I/91y8+OB3VjL._SY466_.jpg',
            description: "Affirms the bereaved. Educates the un-bereaved. A building-block for children",
            category: ["Books"],
            subcategory: ["Grief"]
        },
        {
            name: 'What Do I Do with Worry?',
            author: 'Dr. Josh Straub & Christi Straub',
            url: 'https://amzn.to/3TwOahD',
            imageUrl: 'https://m.media-amazon.com/images/I/81Uwqm2nA2L._SY342_.jpg',
            description: "Willow's story offers practical lessons to help worry birds fly away.",
            category: ["Books"],
            subcategory: ["Kids"]
        },
        {
            name: 'My Mouth Is A Volcano',
            author: 'Julia Cook',
            url: 'https://amzn.to/3Tnmn2Y',
            imageUrl: 'https://m.media-amazon.com/images/I/8181NkFL6SL._SY466_.jpg',
            description: "An empathetic approach to the habit of interrupting",
            category: ["Books"],
            subcategory: ["Kids"]
        },
        {
            name: 'My Body Sends a Signal:',
            author: 'Natalia Maguire',
            url: 'https://amzn.to/3VuIoj4',
            imageUrl: 'https://m.media-amazon.com/images/I/71GBNAYC4HL._SY466_.jpg',
            description: "Helping Kids Recognize Emotions and Express Feelings",
            category: ["Books"],
            subcategory: ["Kids"]
        },
        {
            name: 'It Will be Okay',
            author: 'Lysa TerKeurst',
            url: 'https://amzn.to/3TDkks2',
            imageUrl: 'https://m.media-amazon.com/images/I/81UQuCfMoXL._SY466_.jpg',
            description: "Trusting God Through Fear and Change",
            category: ["Books"],
            subcategory: ["Kids"]
        },
        {
            name: 'Thought-Spot I Know What To Do Flipbook',
            author: '',
            url: 'https://amzn.to/3x9TAaZ',
            imageUrl: 'https://m.media-amazon.com/images/I/61xUmdG-apL.__AC_SX300_SY300_QL70_FMwebp_.jpg',
            description: "Emotions Chart for Kids",
            category: ["Books"],
            subcategory: ["Kids"]
        },
        {
            name: "It Didn't Start with You",
            author: 'Mark Wolynn',
            url: 'https://amzn.to/3PvArqf',
            imageUrl: 'https://m.media-amazon.com/images/I/71mczBxEiOL._SY466_.jpg',
            description: "How Inherited Family Trauma Shapes Who We Are and How to End the Cycle",
            category: ["Books"],
            subcategory: ["Trauma"]
        },
        {
            name: 'The Body Keeps the Score',
            author: 'Bessel van der Kolk M.D.',
            url: 'https://amzn.to/3x9YvbV',
            imageUrl: 'https://m.media-amazon.com/images/I/41T-XHe8-EL._SY445_SX342_.jpg',
            description: "A pioneering researcher transforms our understanding of trauma and offers a bold new paradigm for healing",
            category: ["Books"],
            subcategory: ["Trauma"]
        },
        {
            name: 'Device Detox',
            author: 'Dr. Brenna Hicks',
            url: 'https://amzn.to/4cqW0C5',
            imageUrl: 'https://m.media-amazon.com/images/I/41G9tre88vL._SY445_SX342_.jpg',
            description: "A Parent's Guide To Reducing Usage, Preventing Tantrums, And Raising Happier Kids",
            category: ["Books"],
            subcategory: ["Parenting"]
        },
        {
            name: 'Raising Good Humans',
            author: 'Hunter Clarke-Fields MSAE',
            url: 'https://amzn.to/3TtOYUv',
            imageUrl: 'https://m.media-amazon.com/images/I/71TWKixwzWL._SY466_.jpg',
            description: "A Mindful Guide to Breaking the Cycle of Reactive Parenting and Raising Kind, Confident Kids",
            category: ["Books"],
            subcategory: ["Parenting"]
        },
        {
            name: 'How to Help Your Child Clean Up Their Mental Mess',
            author: 'Dr. Caroline Leaf',
            url: 'https://amzn.to/3VOiNCb',
            imageUrl: 'https://m.media-amazon.com/images/I/711mqqv4SrL._SY466_.jpg',
            description: "A Guide to Building Resilience and Managing Mental Health",
            category: ["Books"],
            subcategory: ["Parenting"]
        },
        {
            name: "It's Not Supposed to Be This Way",
            author: 'Lysa TerKeurst',
            url: 'https://amzn.to/4anm8vR',
            imageUrl: 'https://m.media-amazon.com/images/I/8198vWW0x1L._SY466_.jpg',
            description: "Finding Unexpected Strength When Disappointments Leave You Shattered",
            category: ["Books"],
            subcategory: [""]
        },
        {
            name: "Forgiving What You Can't Forget",
            author: 'Lysa TerKeurst',
            url: 'https://amzn.to/3TS7mbe',
            imageUrl: 'https://m.media-amazon.com/images/I/411zcwxn12L._SY445_SX342_.jpg',
            description: "Discover How to Move On, Make Peace with Painful Memories",
            category: ["Books"],
            subcategory: ["Trauma"]
        },
        {
            name: 'Good Boundaries and Goodbyes',
            author: 'Lysa TerKeurst',
            url: 'https://amzn.to/4a2QnZp',
            imageUrl: 'https://m.media-amazon.com/images/I/81-pJov3gwL._SY466_.jpg',
            description: "Loving Others Without Losing the Best of Who You Are",
            category: ["Books"],
            subcategory: [""]
        },
        {
            name: 'Cleaning Up Your Mental Mess',
            author: 'Dr. Caroline Leaf',
            url: 'https://amzn.to/4cm4Uke',
            imageUrl: 'https://m.media-amazon.com/images/I/91acNSjubyL._SY466_.jpg',
            description: "5 Simple, Scientifically Proven Steps to Reduce Anxiety, Stress, and Toxic Thinking",
            category: ["Books"],
            subcategory: [""]
        },
        /*
        {
            name: '',
            author: '',
            url: '',
            imageUrl: '',
            description: "",
            category: ["Books"],
            subcategory: ["Trauma"]
        }, */

        // Add more products as needed
    ];
    const [selectedCategories, setSelectedCategories] = useState(['All']);

    const handleCheckboxChange = (category) => {
        setSelectedCategories((prevCategories) => {
            if (prevCategories.includes(category)) {
                // Remove category if already selected
                return prevCategories.filter((c) => c !== category);
            } else {
                // Add category if not selected
                return [...prevCategories, category];
            }
        });
    };

    useEffect(() => {
        // Pre-check 'Books' and 'Toys' by default
        setSelectedCategories(['Books', 'Toys']);
    }, []); // Empty dependency array ensures this effect runs only once when the component mounts

    const noCategorySelected = selectedCategories.length === 1 && selectedCategories[0] === 'All';

    const filteredProducts = noCategorySelected
        ? productData.slice().sort((a, b) => a.name.localeCompare(b.name)) // Organize all products alphabetically
        : productData
            .filter(product => product.category.some(c => selectedCategories.includes(c)))
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name)); // Organize filtered products alphabetically

    return (
        <div className='recommendations-page'>
            <h1 id='recommendations-page-header'>Product Recommendations</h1>
            <p id='recommendations-page-intro'>Welcome to our carefully curated product recommendations page, where we've handpicked a selection of enriching books and engaging fidget toys to elevate your well-being journey. Dive into captivating reads that inspire personal growth or explore our range of sensory-friendly fidget toys designed to bring comfort and focus.

                Please note that these recommendations contain affiliate links, and by making a purchase, you support our office as we may earn a commission.</p>



            <div className='recommendations-checkbox-container'>

                <label id="recommendations-checkbox">
                    <input
                        type="checkbox"
                        checked={selectedCategories.includes('Books')}
                        onChange={() => handleCheckboxChange('Books')}
                    />
                    Books
                </label>

                <label id="recommendations-checkbox">
                    <input
                        type="checkbox"
                        checked={selectedCategories.includes('Toys')}
                        onChange={() => handleCheckboxChange('Toys')}
                    />
                    Toys
                </label>


            </div>

            {noCategorySelected && <p >Please select a product category.</p>}
            {!noCategorySelected && selectedCategories.length === 0 && <p id='recommendations-page-none-selected'>Please select at least one product category.</p>}

            <div className='recommendations-product-container'>
                <ProductList products={filteredProducts} />
            </div>
        </div>
    );
};

export default Recommendations;
